import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 50px;
  max-width: 80%;

  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    max-width: 520px;
  }
`;

export const CompanyName = styled.div`
  color: ${({ theme }) => theme.color.text.secondaryLessTransparent};
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 18px;
`;

export const CompanyInfo = styled.div`
  color: ${({ theme }) => theme.color.text.secondary};
`;

export const Separator = styled.div`
  margin-bottom: 100px;
`;
