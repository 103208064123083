import React from 'react';
import { CompanyInfo, CompanyName, Wrapper } from './styled';

interface Props {
  companyName: string;
  contactInfo: string[];
}

const ContactInfo = ({ companyName, contactInfo }: Props) => {
  return (
    <Wrapper>
      <CompanyName>{companyName}</CompanyName>
      {contactInfo.map((info, index) => (
        <CompanyInfo key={index}>{info}</CompanyInfo>
      ))}
    </Wrapper>
  );
};

export default ContactInfo;
