import Footer from 'components/CommonComponents/Footer';
import MessageUs, { TitleType } from 'components/CommonComponents/Forms/MessageUsForm';
import NavigationBar from 'components/CommonComponents/NavigationBar';
import SEO from 'components/CommonComponents/SEO';
import { StyledWrapper } from 'components/CommonComponents/Wrappers/styled';
import ContactInfo from 'components/PageContact';
import { Separator } from 'components/PageContact/styled';
import { GlobalStyle } from 'constants/globalTheme';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

const pageQuery = graphql`
  {
    messageusYaml {
      email {
        cs
      }
      header {
        cs
      }
      buttonText {
        cs
      }
      name {
        cs
      }
      message {
        cs
      }
      contactInfo {
        cs
      }
      companyName
    }
  }
`;

const ContactPage = () => {
  const locale = 'cs';
  const data = useStaticQuery(pageQuery);

  return (
    <>
      <SEO title="Kontakt" />
      <GlobalStyle />

      <NavigationBar />
      <StyledWrapper>
        <MessageUs
          buttonText={data.messageusYaml.buttonText[locale]}
          mainHeading={data.messageusYaml.header[locale]}
          messagePlaceholder={data.messageusYaml.message[locale]}
          namePlaceholder={data.messageusYaml.name[locale]}
          emailPlaceholder={data.messageusYaml.email[locale]}
          imageLink={data.messageusYaml.backgroundImage}
          titleType={TitleType.H1}
        />
        <ContactInfo companyName={data.messageusYaml.companyName} contactInfo={data.messageusYaml.contactInfo[locale]} />
        <Separator />
      </StyledWrapper>
      <Footer />
    </>
  );
};

export default ContactPage;
